var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout scroll-behavior mdesign-overview-content" },
    [
      _c(
        "div",
        { staticClass: "loader-wrapper" },
        [_c("loader", { attrs: { loader: _vm.loader } })],
        1
      ),
      _vm.showModalConfig
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "template-modal" },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("askProceed"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "md-layout modal-content" }, [
                          _c("div", {
                            staticClass: "md-layout-item md-small-size-100",
                          }),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                4086843832
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "md-layout" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-33 just-center" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-round md-primary",
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-33 just-center" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-round md-simple",
                          on: { click: _vm.toTemplates },
                        },
                        [_vm._v(_vm._s(_vm.$t("withTemplates")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-33 just-center" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-round md-simple",
                          on: { click: _vm.toProducts },
                        },
                        [_vm._v(_vm._s(_vm.$t("withProducts")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.showModalCopyConfig
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "copy-config-modal" },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h3", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("chooseConfiguration"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "md-layout copy-exist-config-content",
                          },
                          [
                            _vm._l(_vm.usableConfigs, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "md-layout-item md-size-100",
                                },
                                [
                                  item.ConfigurationID !== "NULL"
                                    ? _c(
                                        "md-field",
                                        {
                                          staticClass:
                                            "modal-product list-row md-custom-field",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "modal-span",
                                              attrs: { type: "text", id: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkCopyOrUpgrade(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.productNames[
                                                    item.ProductID
                                                  ] +
                                                    " " +
                                                    item.Info
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.usableConfigs.length === 0
                                    ? _c("div", {}, [
                                        _vm._v(
                                          _vm._s(_vm.$t("noUsableConfigs"))
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-size-100 text-right",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-primary md-round",
                                    on: { click: _vm.closeModal },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("cancel")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                109809715
              ),
            },
            [_c("template", { slot: "footer" })],
            2
          )
        : _vm._e(),
      _vm.showModalDescription
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { modalWidth: "600", name: "template-modal" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("infoText"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "md-layout modal-content" }, [
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100",
                                },
                                [
                                  _c(
                                    "md-field",
                                    {
                                      staticClass: "has-count-textarea",
                                      class: { "too-long": _vm.getTooLong },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("name"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          id: "infoName",
                                          maxlength: "30",
                                        },
                                        model: {
                                          value: _vm.infoName,
                                          callback: function ($$v) {
                                            _vm.infoName = $$v
                                          },
                                          expression: "infoName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100",
                                },
                                [
                                  _c(
                                    "md-field",
                                    {
                                      staticClass: "has-count-textarea",
                                      class: { "too-long": _vm.getTooLong },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("description"))),
                                      ]),
                                      _c("md-textarea", {
                                        attrs: {
                                          type: "text",
                                          id: "infoText",
                                          maxlength: "222",
                                        },
                                        model: {
                                          value: _vm.infoText,
                                          callback: function ($$v) {
                                            _vm.infoText = $$v
                                          },
                                          expression: "infoText",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2289540384
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "space-evenly" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-primary",
                        attrs: { disabled: _vm.getTooLong },
                        on: { click: _vm.updateConfiguration },
                      },
                      [_vm._v(_vm._s(_vm.$t("save")))]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-simple",
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v(_vm._s(_vm.$t("cancel")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100",
          },
          [
            _c(
              "div",
              { staticClass: "product-info center" },
              [
                _c(
                  "md-card",
                  { staticClass: "product-info-card" },
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass:
                          "md-card-header-icon md-card-header-primary",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon" },
                          [_c("md-icon", [_vm._v("info")])],
                          1
                        ),
                        _c("h4", [_vm._v(_vm._s(_vm.$t("productInfo")))]),
                        _c("h3", {}, [
                          _vm._v(
                            _vm._s(
                              _vm.configurationProductLevelStore.product.Title
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c("md-card-content", [
                      _c("div", {}, [
                        _c("div", { staticClass: "md-layout" }, [
                          _vm.allow("{43b096e1-db56-4bbc-abcf-627cc1a8e1d9}")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-100 pb-10",
                                },
                                [
                                  _c("div", { staticClass: "nav-name" }, [
                                    _c(
                                      "h5",
                                      {
                                        staticClass: "mt-0",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toProfile()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.companyStore.company.Name)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "md-field",
                                { staticClass: "md-space-between" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.activeCompanyHasProduct.Info)
                                    ),
                                  ]),
                                  _vm.allow(
                                    "{d7a9f64c-3155-4e96-aef7-c1869051b1d7}"
                                  )
                                    ? _c(
                                        "div",
                                        { staticClass: "icon-container" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "animated-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEditInfo()
                                                },
                                              },
                                            },
                                            [_vm._v("edit")]
                                          ),
                                          _c("md-tooltip", [
                                            _vm._v(_vm._s(_vm.$t("edit"))),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.configurationProductLevelStore.product
                                      .Subtitle
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.configurationProductLevelStore.product
                                      .Teaser
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "md-layout-item just-right",
                            on: {
                              drop: function ($event) {
                                $event.preventDefault()
                                return _vm.onFileChange.apply(null, arguments)
                              },
                              dragover: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDragOver.apply(null, arguments)
                              },
                              dragleave: _vm.handleDragLeave,
                            },
                          },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-primary md-round md-fileinput",
                              },
                              [
                                _vm._v(_vm._s(_vm.$t("loadLicFile")) + " "),
                                _c("input", {
                                  attrs: { accept: ".lic", type: "file" },
                                  on: { change: _vm.onFileChange },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100",
          },
          [
            _vm.selectedConfiguration !== null
              ? _c("mdesign-configuration", {
                  key: _vm.renderKey,
                  attrs: { propConfiguration: _vm.selectedConfiguration },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.allow("{60d57c5c-bd17-405c-9e1e-290038937b07}")
          ? _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100",
              },
              [
                _c(
                  "md-card",
                  { staticClass: "md-card-configurations-overview" },
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass:
                          "md-card-header-icon md-card-header-primary",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon" },
                          [_c("md-icon", [_vm._v("list")])],
                          1
                        ),
                        _c("h4", [_vm._v(_vm._s(_vm.$t("configurations")))]),
                      ]
                    ),
                    _c("md-card-content", [
                      _vm.configurations.length === 0
                        ? _c(
                            "div",
                            { staticClass: "md-layout" },
                            [
                              _c("md-field", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-100 md-space-between mh-34-5",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("noConfigurations"))
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.allow("{60d57c5c-bd17-405c-9e1e-290038937b07}")
                        ? _c(
                            "div",
                            { key: _vm.renderKey, staticClass: "md-layout" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-100 md-space-between config-content",
                                },
                                [
                                  _c(
                                    "md-field",
                                    { staticClass: "md-custom-field" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-container col-header col-info",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("info"))),
                                          ]),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass: "col-container",
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-container col-header col-date",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("date"))),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-container col-header controls",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("controls"))),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.configurations,
                                function (config, index) {
                                  return _c(
                                    "md-field",
                                    {
                                      key: config.ID,
                                      staticClass: "md-custom-field",
                                      class: { "bg-table": index % 2 !== 0 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-100 md-space-between config-content",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-container col-info",
                                              class: {
                                                "inactive-item":
                                                  !config.isActive,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "fl-col" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getConfigInfoName(
                                                          config
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "config-info",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getConfigInfo(
                                                            config
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("md-tooltip", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.configTooltips[
                                                          config.ID
                                                        ]
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "col-container",
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-container col-date",
                                              class: {
                                                "inactive-item":
                                                  !config.isActive,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      config.Created.split(
                                                        "T"
                                                      )[0]
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "controls col-container",
                                            },
                                            [
                                              config.isActive &&
                                              _vm.allow(
                                                "{63de84e0-d489-42d2-afd8-d817b18e8a52}"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon-container",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "animated-icon",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleEdit(
                                                                config
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("edit")]
                                                      ),
                                                      _c("md-tooltip", [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("edit"))
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : !config.isActive &&
                                                  _vm.showControls
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon-container",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "animated-icon",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleEditDescription(
                                                                config
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("edit")]
                                                      ),
                                                      _c("md-tooltip", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "editDescritption"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !config.isActive &&
                                              _vm.allow(
                                                "{1c2f6123-6626-48b5-996b-18816c9e1db9}"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon-container",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "animated-icon",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDelete(
                                                                config
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("delete")]
                                                      ),
                                                      _c("md-tooltip", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("delete")
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "icon-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "animated-icon",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleProtocol(
                                                            config
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("visibility")]
                                                  ),
                                                  _c("md-tooltip", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("showProtocol")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _c(
                            "div",
                            { staticClass: "md-layout" },
                            [
                              _c("md-field", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-100 md-space-between",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.selectedConfiguration.Info)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-100 text-right pt-15",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pr-10" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-simple md-round",
                                  attrs: {
                                    disabled: _vm.usableConfigs.length === 0,
                                  },
                                  on: { click: _vm.handleCopy },
                                },
                                [_vm._v(_vm._s(_vm.$t("choose")) + " ")]
                              ),
                              _c("md-tooltip", [
                                _vm._v(_vm._s(_vm.$t("useExistingConfig"))),
                              ]),
                            ],
                            1
                          ),
                          _vm.configurations.length === 0
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-primary md-round btn-custom",
                                  attrs: { disabled: _vm.noMasterConfig },
                                  on: { click: _vm.handleCreate },
                                },
                                [_vm._v(_vm._s(_vm.$t("create")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100",
          },
          [
            _vm.isLicencer ||
            _vm.allow("{1e63849b-31df-4d00-a883-3a80f39c0c9e}")
              ? _c(
                  "md-card",
                  { staticClass: "md-card-hostid-file" },
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass:
                          "md-card-header-icon md-card-header-primary",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon" },
                          [_c("md-icon", [_vm._v("list")])],
                          1
                        ),
                        _c("h4", {}, [_vm._v(_vm._s(_vm.$t("hostIDs")))]),
                      ]
                    ),
                    _c("md-card-content", [
                      _c(
                        "div",
                        { staticClass: "md-layout" },
                        [
                          _vm.hostFiles.length === 0
                            ? _c("md-field", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-100 md-space-between mh-34-5",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(this.$t("noHostFile"))),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.hostFiles.length > 0
                        ? _c(
                            "div",
                            { staticClass: "md-layout" },
                            [
                              _c(
                                "md-field",
                                { staticClass: "md-custom-field" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-100 md-space-between config-content",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-container col-header col-info",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("info"))),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-container col-header col-mcorpc",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("mcOrPc"))),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-container col-header col-date",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("date"))),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-container col-header controls",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("controls"))),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._l(_vm.hostFiles, function (hostFile) {
                                return _c(
                                  "md-field",
                                  {
                                    key: hostFile.ID,
                                    staticClass: "md-custom-field",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-100 md-space-between mh-34-5",
                                        class: {
                                          "inactive-item": hostFile.Disable,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-container col-info",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  hostFile.Comment !== ""
                                                    ? hostFile.Comment
                                                    : hostFile.ServerName
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-container" },
                                          [
                                            hostFile.IsMacAddress
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(hostFile.MacAddress1)
                                                  ),
                                                ])
                                              : hostFile.IsPcName
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(hostFile.PcName1)
                                                  ),
                                                ])
                                              : hostFile.IsMacAddressAndPcName
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(hostFile.MacAddress1)
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    _vm._s(hostFile.PcName1)
                                                  ),
                                                ])
                                              : hostFile.Triade
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(hostFile.MacAddress1)
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    _vm._s(hostFile.MacAddress2)
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    _vm._s(hostFile.MacAddress3)
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-container col-date",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatDate(
                                                    hostFile.Created.split(
                                                      "T"
                                                    )[0]
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-container controls",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "icon-container" },
                                              [
                                                _vm.showControls ||
                                                _vm.allow(
                                                  "{bc3f88f2-ee40-40c9-aff4-e38fedd2dab8}"
                                                )
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "animated-icon",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleSendLicense(
                                                              hostFile
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("email")]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "md-tooltip",
                                                  {
                                                    attrs: {
                                                      "md-direction": "bottom",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("sendLicence")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "icon-container" },
                                              [
                                                _vm.showControls ||
                                                _vm.allow(
                                                  "{b72f78c1-fe04-40a2-ac81-567d5243ce2d}"
                                                )
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "animated-icon",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDownloadLicence(
                                                              hostFile
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("file_download")]
                                                    )
                                                  : _vm._e(),
                                                _c("md-tooltip", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "downloadLicenceFile"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "icon-container" },
                                              [
                                                _vm.showControls ||
                                                _vm.allow(
                                                  "{21d902eb-2022-4553-8140-7fb564abf530}"
                                                )
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "animated-icon",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleEditHost(
                                                              hostFile
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("edit")]
                                                    )
                                                  : _vm._e(),
                                                _c("md-tooltip", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("edit"))
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "icon-container" },
                                              [
                                                _vm.showControls ||
                                                _vm.allow(
                                                  "{c0462ad2-a430-4db7-a454-8b535dfd3e20}"
                                                )
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "animated-icon",
                                                        class: {
                                                          selected:
                                                            !hostFile.Disable,
                                                          "not-selected":
                                                            hostFile.Disable,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleActive(
                                                              hostFile
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "noise_control_off"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("md-tooltip", [
                                                  _vm._v(
                                                    _vm._s(
                                                      hostFile.Disable
                                                        ? _vm.$t("activate")
                                                        : _vm.$t("deactivate")
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.allow("{96aa19ab-96e5-4c1f-b258-40a630b00e74}")
                        ? _c(
                            "div",
                            { staticClass: "md-layout pt-15 btn-container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-100 text-right",
                                },
                                [
                                  _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-primary md-round btn-custom",
                                      on: { click: _vm.handleCreateHost },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("create")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }