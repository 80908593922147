import { defineStore } from 'pinia'
import {
  ApiNames,
  httpGetAuth,
  httpPostAuth,
  httpPost,
  httpGetByUrl,
  httpGetAuthFileDownload,
  httpDelete,
  httpGetAws,
  httpPostProductLevelOneImage,
  httpPostUploadLicense,
  httpGetLicenceFile,
} from '@/libs/httpHelper'
import { useUserStore } from './user.js'
import { configurationStore } from './configuration.js'
import { httpGet } from '../libs/httpHelper.js'
import Swal from 'sweetalert2'
import { i18n } from './../main.js'
import { useCompanyStore } from './company.js'
import LoggingService from '@/libs/loggingService.js'
import { saveAs } from 'file-saver'

const logger = new LoggingService('C:/Users/skissling/OneDrive - TEDATA GmbH/Desktop/log-file.txt')

export const useConfigurationProductLevelStore = defineStore({
  id: 'configurationProductLevelState',
  // arrow function recommended for full type inference
  state: () => {
    return {
      isReadonly: false,
      edit: false,
      updateAllConfigs: false,
      isContactProduct: false,
      masterVorlage: {
        configurationLevel_1: [],
        configurationLevel_2: [],
        configurationLevel_3: [],
      },
      configurationTemplates: [],
      configurationVorlage: {
        ID: '',
        Count: 0,
        configurationLevel_1: [],
        configurationLevel_2: [],
        configurationLevel_3: [],
      },
      imageData: {},
      productsLevel_1: [],
      productsLevel_2: [],
      productsLevel_3: [],
      configurationModel: {
        ID: '',
        Name: '',
        valid: false,
        CompanyName: '',
        CustomerNr: '',
        Version: 0,
        VersionType: '',
        ProductsLevel_1: [
          {
            ProductsLevel_2: [
              {
                ProductsLevel_3: [],
              },
            ],
          },
        ],
      },
      masterModel: {
        ConfigurationID: '',
        ProductsLevel_1: [
          {
            ProductsLevel_2: [
              {
                ProductsLevel_3: [],
              },
            ],
          },
        ],
        technicalDependencies: []
      },
      licenceCreatorModel: {
        isValid: false,
        ID: '',
        Disable: false,
        StartDate: '',
        EndDate: '',
        CompanyNameShort: '',
        Comment: '',
        ConfigurationID: '',
        Salutation: '',
        Firstname: '',
        LastName: '',
        CompanyName: '',
        Receiver: '',
        Version: 0,
        Unlimited: false,
        TerminalServer: false,
        VirtualServer: false,
        Enddate: '',
        HostIDCreateDate: '',
        CustomerNr: '',
        MacAddress: false,
        ComputerName: false,
        MacAndComputerName: false,
        Triade: false,
        Unbound: false,
        Port: 0,
        MacAddress1: '',
        PcName1: '',
        MacAddress2: '',
        PcName2: '',
        MacAddress3: '',
        PcName3: '',
        ServerHostID: '',
        ServerName: '',
        InstallationType: '',
        Build: '',
        OperationSystem: '',
        OperationSystem: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      product: {
        ID: '',
        ProductIDExtern: -1,
        Code: '',
        Name: '',
        Title: '',
        Subtitle: '',
        Teaser: '',
        Link: '',
        Image: -1,
        TitleEN: '',
        SubtitleEN: '',
        TeaserEN: '',
        LinkEN: '',
        ImageEN: -1,
        RuntimeInDays: 0,
        Starttime: '',
        Endtime: '',
      },
      activeProduct: {},
      productHasConfiguration: [],
      hostIDFiles: [],
      productHasProductLevelOne: [],
      protocolData: {
        Version: -1.0,
        VersionType: '',
        ConfigurationID: ''
      },
      upgradeData: {
        oldProductID: ""
      }
    }
  },
  persist: true,
  getters: {
    // ...
  },
  actions: {
    /**
     * Resets all Data in this Store
     */
    resetConfigurationProductLevelStore() {
      this.isReadonly = false
      this.edit = false
      this.updateAllConfigs = false
      this.isContactProduct = false
      this.masterVorlage = {
        configurationLevel_1: [],
        configurationLevel_2: [],
        configurationLevel_3: [],
      }
      this.configurationTemplates = []
      this.configurationVorlage = {
        ID: '',
        Count: 0,
        configurationLevel_1: [],
        configurationLevel_2: [],
        configurationLevel_3: [],
      }
      this.productsLevel_1 = []
      this.productsLevel_2 = []
      this.productsLevel_3 = []
      this.configurationModel = {
        ID: '',
        Count: 0,
        configurationLevel_1: [],
        configurationLevel_2: [],
        configurationLevel_3: [],
      }
      this.productsLevel_1 = []
      this.productsLevel_2 = []
      this.productsLevel_3 = []
      this.configurationModel = {
        ID: '',
        Name: '',
        valid: false,
        CompanyName: '',
        CustomerNr: '',
        Version: 0,
        VersionType: '',
        ProductsLevel_1: [
          {
            ProductsLevel_2: [
              {
                ProductsLevel_3: [],
              },
            ],
          },
        ],
      }
      this.masterModel = {
        ConfigurationID: '',
        ProductsLevel_1: [
          {
            ProductsLevel_2: [
              {
                ProductsLevel_3: [],
              },
            ],
          },
        ],
        technicalDependencies: []
      }
      this.licenceCreatorModel = {
        isValid: false,
        ID: '',
        StartDate: '',
        EndDate: '',
        Disable: false,
        CompanyNameShort: '',
        Comment: '',
        ConfigurationID: '',
        Salutation: '',
        Firstname: '',
        LastName: '',
        CompanyName: '',
        Receiver: '',
        Version: 0,
        Unlimited: false,
        TerminalServer: false,
        VirtualServer: false,
        Enddate: '',
        HostIDCreateDate: '',
        CustomerNr: '',
        MacAddress: false,
        ComputerName: false,
        MacAndComputerName: false,
        Triade: false,
        Unbound: false,
        Port: 0,
        MacAddress1: '',
        PcName1: '',
        MacAddress2: '',
        PcName2: '',
        MacAddress3: '',
        PcName3: '',
        ServerHostID: '',
        ServerName: '',
        InstallationType: '',
        Build: '',
        OperationSystem: '',
        OperationSystem: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      }
      this.product = {
        ID: '',
        ProductIDExtern: -1,
        Code: '',
        Name: '',
        Title: '',
        Subtitle: '',
        Teaser: '',
        Link: '',
        Image: -1,
        TitleEN: '',
        SubtitleEN: '',
        TeaserEN: '',
        LinkEN: '',
        ImageEN: -1,
        RuntimeInDays: 0,
        Starttime: '',
        Endtime: '',
      }
      this.activeProduct = {}
      this.productHasConfiguration = []
      this.hostIDFiles = []
      this.productHasProductLevelOne = []
      this.protocolData = {
        Version: -1.0,
        VersionType: '',
        ConfigurationID: ''
      }
      this.upgradeData = {
        oldProductID: ""
      }
    },
    resetConfigurationModel() {
      this.configurationModel = {
        ID: '',
        Name: '',
        CompanyName: '',
        CustomerNr: '',
        Version: '',
        ProductsLevel_1: [
          {
            ProductsLevel_2: [
              {
                ProductsLevel_3: [],
              },
            ],
          },
        ],
      }
    },
    resetConfigurationVorlage() {
      this.configurationVorlage = {
        ID: '',
        Count: 0,
        configurationLevel_1: [],
        configurationLevel_2: [],
        configurationLevel_3: [],
      }
    },
    resetMasterModel() {
      this.masterModel = {
        ConfigurationID: '',
        ProductsLevel_1: [
          {
            ProductsLevel_2: [
              {
                ProductsLevel_3: [],
              },
            ],
          },
        ],
      }
      this.masterVorlage = {
        configurationLevel_1: [],
        configurationLevel_2: [],
        configurationLevel_3: [],
      }
    },
    /**
     * Gets all Configuration_Levels of a given Configuration ID (this.configurationVorlage)
     * and creates a Model with it (this.configurationModel)
     *
     * @param {String} configId = configuration ID
     * @param {String} token = user Token
     * @returns
     */
    async createMdesignConfigurationModel(configId, token) {
      const companyStore = useCompanyStore()

      var resL1 = await this.getConfigurationLevel_1(configId, token)
      if (resL1.status !== 200) {
        this.fireError(resL1.statusText)
        return
      }
      this.configurationVorlage.configurationLevel_1 = resL1.data

      var resL2 = await this.getConfigurationLevel_2(configId, token)

      if (resL2.status !== 404) {
        if (resL2.status !== 200 && resL2.status !== 404) {
          this.fireError(resL2.statusText)
          return
        }
        this.configurationVorlage.configurationLevel_2 = resL2.data
      }

      var resL3 = await this.getConfigurationLevel_3(configId, token)
      if (resL3.status !== 404) {
        if (resL3.status !== 200) {
          this.fireError(resL3.statusText)
          return
        }
        this.configurationVorlage.configurationLevel_3 = resL3.data
      }
      this.configurationVorlage.ID = configId

      const vorlageName = 'configurationVorlage'
      const modelName = 'configurationModel'
      this.createModelWithVorlage(vorlageName, modelName)
      var respConf = await this.getConfiguration(configId, token)
      this.configurationModel.Name = respConf.data[0].Info
      this.configurationModel.ID = respConf.data[0].ID
      this.configurationModel.CompanyName = companyStore.company.Name
      this.configurationModel.CustomerNr = companyStore.company.CustomerNr
      this.configurationModel.Version = this.product.Version

      if (this.product.Title.includes('Einzelplatz'))
        this.configurationModel.VersionType = 'Einzelplatz'
      else if (this.product.Title.includes('Netzwerk'))
        this.configurationModel.VersionType = 'Netzwerk'
      else
        this.configurationModel.VersionType = 'undefined'

      return this.configurationModel
    },

    async getConfiguration(configID, token) {
      const params = { id: configID }

      var response = await httpGetAuth(ApiNames.configuration, params, {}, token)
      return response
    },

    async createMasterConfigurationModel(configId, token) {
      await this.getProductLevels_1(token)
      await this.getProductLevels_2(token)
      await this.getProductLevels_3(token)

      var resL1 = await this.getConfigurationLevel_1(configId, token)
      if (resL1.status === 200) {
        this.masterVorlage.configurationLevel_1 = resL1.data
      } else if (resL1.status === 404) {
        this.masterVorlage.configurationLevel_1 = []
      } else {
        this.fireError(resL1.statusText)
        return
      }

      var resL2 = await this.getConfigurationLevel_2(configId, token)
      if (resL2.status === 200) {
        this.masterVorlage.configurationLevel_2 = resL2.data
      } else if (resL2.status === 404) {
        this.masterVorlage.configurationLevel_2 = []
      } else {
        this.fireError(resL2.statusText)
        return
      }

      var resL3 = await this.getConfigurationLevel_3(configId, token)
      if (resL3.status === 200) {
        this.masterVorlage.configurationLevel_3 = resL3.data
      } else if (resL3.status === 404) {
        this.masterVorlage.configurationLevel_3 = []
      } else {
        this.fireError(resL3.statusText)
        return
      }

      const vorlageName = 'masterVorlage'
      const modelName = 'masterModel'
      this.createModelWithVorlage(vorlageName, modelName)
      this.masterModel.ConfigurationID = configId

      // get technical dependencies
      var resProductHasProuctLevelOne = await this.getProductHasProductLevelOne(this.product.ID, token)
      if (resProductHasProuctLevelOne.status === 200) this.productHasProductLevelOne = resProductHasProuctLevelOne.data
      //console.log("masterModel ", this.masterModel)
      this.masterModel.technicalDependencies = []

      if (this.productHasProductLevelOne.length > 0) {
        // this.productHasProductLevelOne.forEach((pHpL1) => {
        //   if(!pHpL1.Visible) {
        //     var master_prod_L1 = this.masterModel.ProductsLevel_1.find(e => e.ProductLevelOneID === pHpL1.ProductLevelOneID)
        //     this.masterModel.technicalDependencies.push(master_prod_L1)
        //     var index = this.masterModel.ProductsLevel_1.findIndex(e => e.ProductLevelOneID === pHpL1.ProductLevelOneID)
        //     this.masterModel.ProductsLevel_1.splice(index, 1)
        //   }
        //   if(pHpL1.Visible) {
        //     var master_prod_L1 = this.masterModel.ProductsLevel_1.find(e => e.ProductLevelOneID === pHpL1.ProductLevelOneID)
        //     this.masterModel.technicalDependencies.push(master_prod_L1)
        //   }

        // })

        //-----------------------

        // this.addTechnicalDependenciesToMasterModel()
        //ersetzen durch addTechnicalDependenciesToMasterModel()
        // this.masterModel.ProductsLevel_1.forEach((level_1) => {
        //   this.productHasProductLevelOne.forEach((productHasLevel1) => {
        //     if (level_1.ProductLevelOneID === productHasLevel1.ProductLevelOneID && !productHasLevel1.Visible) {
        //       level_1.Invisible = true
        //     }
        //   })
        // })
        //-----------------------
      }
    },

    /**
     * Create a Model with a given configuration
     * saves the model under chosen name
     * the configuration must be preloaded and saved under a template(vorlage)
     *
     * @param {String} vorlageName - the name of the template
     * @param {String} modelName - the name the model
     */
    createModelWithVorlage(vorlageName, modelName) {
      this[modelName].ProductsLevel_1 = []

      if (this[vorlageName].configurationLevel_1.length <= 0) {
        return
      }
      this[vorlageName].configurationLevel_1.forEach((config) => {
        config.Limited.includes('T') ? (config.Limited = config.Limited.split('T')[0]) : undefined
        var product = this.productsLevel_1.find((prod) => prod.ID === config.ProductLevelOneID)
        this[modelName].ProductsLevel_1.push({
          Name: product.Name,
          ID: '',
          ProductLevelOneID: config.ProductLevelOneID,
          ConfigurationID: '',
          Count: vorlageName === 'masterVorlage' ? 0 : config.Count,
          Limited: config.Limited,
          CreatedBy: '',
          Created: '',
          ModifiedBy: '',
          Modified: '',
          isSelected: false,
          isDependency: product.Name.includes('techDependency') || config.Count === -1 ? true : false,
          StartDate: config.StartDate,
          Sorting: config.Sorting,
          Connect: config.Connect,
          EditModules: config.EditModules,
          Student: config.Student,
          Test: config.Test,
          TestCount: config.TestCount,
          Uncounted: config.Uncounted,
          LicName: product.LicName,
          LicNameParent: product.LicNameParent,
          Image: product.Image,
          ProductsLevel_2: [],
        })
      })

      this[modelName].ProductsLevel_1.forEach((level_1) => {
        this[vorlageName].configurationLevel_2.forEach((level_2) => {
          var product = this.productsLevel_2.find((prod) => prod.ID === level_2.ProductLevelTwoID)
          if (level_1.ProductLevelOneID === level_2.ProductLevelOneID) {
            level_2.Limited.includes('T') ? (level_2.Limited = level_2.Limited.split('T')[0]) : undefined
            level_1.ProductsLevel_2.push({
              Name: product.Name,
              ID: '',
              ProductLevelOneID: level_2.ProductLevelOneID,
              ProductLevelTwoID: level_2.ProductLevelTwoID,
              ConfigurationID: '',
              Count: vorlageName === 'masterVorlage' ? 0 : level_2.Count,
              StartDate: level_2.StartDate,
              Limited: level_2.Limited,
              CreatedBy: '',
              Created: '',
              ModifiedBy: '',
              Modified: '',
              Sorting: level_2.Sorting,
              LicName: product.LicName,
              LicNameParent: product.LicNameParent,
              ProductsLevel_3: [],
            })
          }
        })
      })

      this[modelName].ProductsLevel_1.forEach((level_1) => {
        level_1.ProductsLevel_2.forEach((level_2) => {
          this[vorlageName].configurationLevel_3.forEach((level_3) => {
            var product = this.productsLevel_3.find((prod) => prod.ID === level_3.ProductLevelThreeID)
            if (level_2.ProductLevelTwoID === level_3.ProductLevelTwoID) {
              level_3.Limited.includes('T') ? (level_3.Limited = level_3.Limited.split('T')[0]) : undefined
              level_2.ProductsLevel_3.push({
                Name: product.Name,
                ID: '',
                ProductLevelTwoID: level_3.ProductLevelTwoID,
                ProductLevelThreeID: level_3.ProductLevelThreeID,
                ConfigurationID: '',
                Count: vorlageName === 'masterVorlage' && level_3.Count !== -1 ? 0 : level_3.Count,
                Limited: level_3.Limited,
                CreatedBy: '',
                Created: '',
                ModifiedBy: '',
                Modified: '',
                Sorting: level_3.Sorting,
                LicName: product.LicName,
                LicNameParent: product.LicNameParent,
              })
            }
          })
        })
      })

      if (vorlageName === 'masterVorlage') this.getImages()
    },
    async createProductConfiguration(token, infoText) {
      const userStore = useUserStore()
      const companyStore = useCompanyStore()
      const userID = userStore.userData.ID
      const companyID = companyStore.company.ID
      const oldConfigurationID = this.activeProduct.ConfigurationID

      // creating deep copies of Models to be sure they dont get changed
      // const copyConfigurationModel = JSON.parse(JSON.stringify(this.configurationModel))
      // removing the technical Dependencies which are not actually deselected
      const copyConfigurationModel = this.removeUnnessesaryTechnicalDependencies()
      var configuration = {
        Info: infoText,
        CompanyID: companyID,
        CreatedBy: userID,
        ModifiedBy: userID,
      }

      var resConfiguration = await this.createConfiguration(configuration, token)
      if (resConfiguration.status !== 200) return false

      const configurationID = resConfiguration.data
      // var companyHasProductID
      if (this.updateAllConfigs) {
        // update all company_has_product

        await Promise.all(
          companyStore.productData.companyHasProduct.map(async (cHp) => {
            if (cHp.ConfigurationID === oldConfigurationID) {
              var obj = cHp
              obj.ConfigurationID = configurationID
              obj.Modified = companyStore.getNow()
              obj.ModifiedBy = userStore.userData.ID

              var res = await this.updateCompanyHasProduct(obj, token)
              var objHist = {
                CompanyHasProductID: cHp.ID,
                ConfigurationID: oldConfigurationID,
                Info: cHp.Info,
                ChangeNotes: '',
                CreatedBy: userStore.userData.ID,
                Created: companyStore.getNow(),
                ModifiedBy: userStore.userData.ID,
                Modified: companyStore.getNow(),
              }
              var resHistory = await this.createConfigurationHistory(objHist, userStore.token)
            }
          }),
        )
      } else {
        // update only this company_has_product
        var companyHasProduct = {}

        // create configuration History
        if (oldConfigurationID !== 'NULL') {
          var objHistory = {
            CompanyHasProductID: this.activeProduct.ID,
            ConfigurationID: oldConfigurationID,
            Info: this.activeProduct.Info,
            ChangeNotes: '',
            CreatedBy: userStore.userData.ID,
            Created: companyStore.getNow(),
            ModifiedBy: userStore.userData.ID,
            Modified: companyStore.getNow(),
          }

          var resHistory = await this.createConfigurationHistory(objHistory, userStore.token)
        }

        // update if the product was just added
        this.activeProduct.ConfigurationID = resConfiguration.data

        companyHasProduct.ID = this.activeProduct.ID
        companyHasProduct.ConfigurationID = configurationID
        companyHasProduct.ModifiedBy = userStore.userData.ID
        companyHasProduct.Info = this.activeProduct.Info
        companyHasProduct.CompanyID = this.activeProduct.CompanyID
        companyHasProduct.Disable = this.activeProduct.Disable
        companyHasProduct.FileCount = this.activeProduct.FileCount
        companyHasProduct.ProductID = this.activeProduct.ProductID

        var resCompHasProd = await this.updateCompanyHasProduct(companyHasProduct, token)

        if (resCompHasProd.status !== 200) return false
      }

      var res = await this.createConfigurationHasProductLevels(copyConfigurationModel, configurationID, userID, token)
      if (res === false) return false

      if (!res) return false

      return true
    },

    removeUnnessesaryTechnicalDependencies() {
      let newConfigurationModel = JSON.parse(JSON.stringify(this.configurationModel))
      newConfigurationModel.ProductsLevel_1 = newConfigurationModel.ProductsLevel_1.filter(level_1 => {
        return !(level_1.isDependency && level_1.Count !== -1);
      });
      return newConfigurationModel
    },

    async updateConfigurationLicences(newCompHasProdID, token) {
      const promises = this.hostIDFiles.map(async (item) => {
        try {
          var resHostFile = await this.getConfigurationLicence(item.ID, token)

          if (resHostFile.status !== 200) return false

          let hostFile = resHostFile.data[0]

          hostFile.CompanyHasProductID = newCompHasProdID

          var resUpdate = await this.updateConfigurationLicence(hostFile, token)

          if (resUpdate.status !== 200) return false
        } catch (error) {
          // this.fireAnyError()
          // console.log('error ', error)
          return false
        }
      })
      await Promise.all(promises)

      var resGetFiles = await this.getConfigurationLicenceCompanyHasProductID(newCompHasProdID, token)

      if (resGetFiles.status !== 200) return false

      this.hostIDFiles = resGetFiles.data
    },

    async createConfigurationHasProductLevels(copyConfigurationModel, configurationID, userID, token) {
      var prods_level_1 = []
      var prods_level_2 = []
      var prods_level_3 = []

      try {
        copyConfigurationModel.ProductsLevel_1.forEach((level_1) => {
          var prod_1 = {
            ProductLevelOneID: level_1.ProductLevelOneID,
            ConfigurationID: configurationID,
            Count: level_1.Count,
            Limited: level_1.Limited,
            Sorting: level_1.Sorting,
            CreatedBy: userID,
            ModifiedBy: userID,
            Connect: level_1.Connect,
            EditModules: level_1.EditModules,
            Student: level_1.Student,
            Test: level_1.Test,
            TestCount: level_1.TestCount,
            Uncounted: level_1.Uncounted,
            isDependency: level_1.isDependency ? true : undefined
          }
          if ((prod_1.Count > 0)) {
            prods_level_1.push(prod_1)
            if (level_1.ProductsLevel_2.length > 0) {
              level_1.ProductsLevel_2.forEach((level_2) => {
                var prod_2 = {
                  ProductLevelOneID: level_1.ProductLevelOneID,
                  ProductLevelTwoID: level_2.ProductLevelTwoID,
                  ConfigurationID: configurationID,
                  Count: level_2.Count,
                  Limited: level_2.Limited,
                  Sorting: level_2.Sorting,
                  CreatedBy: userID,
                  ModifiedBy: userID,
                }
                if (prod_2.Count >= 0) {
                  prods_level_2.push(prod_2)
                  if (level_2.ProductsLevel_3.length > 0) {
                    level_2.ProductsLevel_3.forEach((level_3) => {
                      var prod_3 = {
                        ProductLevelTwoID: level_2.ProductLevelTwoID,
                        ProductLevelThreeID: level_3.ProductLevelThreeID,
                        ConfigurationID: configurationID,
                        Count: level_3.Count,
                        Limited: level_3.Limited,
                        Sorting: level_3.Sorting,
                        CreatedBy: userID,
                        ModifiedBy: userID,
                      }
                      prods_level_3.push(prod_3)
                    })
                  }
                }
              })
            }
          }
          // only adding technical dependencies which are active deselected
          else if (prod_1.isDependency && prod_1.Count === -1) {
            prods_level_1.push(prod_1)
            level_1.ProductsLevel_2 = level_1.ProductsLevel_2.filter(el => el.LicName.includes("GROUP"))
            if (level_1.ProductsLevel_2.length > 0) {
              level_1.ProductsLevel_2.forEach((level_2) => {
                var prod_2 = {
                  ProductLevelOneID: level_1.ProductLevelOneID,
                  ProductLevelTwoID: level_2.ProductLevelTwoID,
                  ConfigurationID: configurationID,
                  Count: -1,
                  Limited: level_2.Limited,
                  Sorting: level_2.Sorting,
                  CreatedBy: userID,
                  ModifiedBy: userID,
                }
                prods_level_2.push(prod_2)
                level_2.ProductsLevel_3 = level_2.ProductsLevel_3.filter(el => el.LicName.includes("GROUP"))
                level_2.ProductsLevel_3.forEach((level_3) => {
                  var prod_3 = {
                    ProductLevelTwoID: level_2.ProductLevelTwoID,
                    ProductLevelThreeID: level_3.ProductLevelThreeID,
                    ConfigurationID: configurationID,
                    Count: -1,
                    Limited: level_3.Limited,
                    Sorting: level_3.Sorting,
                    CreatedBy: userID,
                    ModifiedBy: userID,
                  }
                  prods_level_3.push(prod_3)
                })
              })
            }
          }
        })
      } catch (error) {
        return false
      }

      if (prods_level_1.length > 0) {
        var res_level_1 = await this.CreateConfigurationHasProductLevel_1_many(prods_level_1, token)
        if (res_level_1.status !== 200) return false
      }
      if (prods_level_2.length > 0) {
        var res_level_2 = await this.CreateConfigurationHasProductLevel_2_many(prods_level_2, token)
        if (res_level_2.status !== 200) return false
      }
      if (prods_level_3.length > 0) {
        var res_level_3 = await this.CreateConfigurationHasProductLevel_3_many(prods_level_3, token)
        if (res_level_3.status !== 200) return false
      }

      return true
    },
    async createConfigurationTemplate(token, name) {
      const userStore = useUserStore()
      const userID = userStore.userData.ID

      // const copyConfigurationModel = JSON.parse(JSON.stringify(this.configurationModel))
      const copyConfigurationModel = this.removeUnnessesaryTechnicalDependencies()

      const configuration = {
        Info: name,
        CreatedBy: userID,
        ModifiedBy: userID,
      }

      var resConfiguration = await this.createConfiguration(configuration, token)

      const configurationID = resConfiguration.data

      var resProductLevels = await this.createConfigurationHasProductLevels(
        copyConfigurationModel,
        configurationID,
        userID,
        token,
      )

      let sorting = 0
      this.productHasConfiguration.forEach((con) => {
        if (sorting <= con.Sorting) {
          sorting = con.Sorting + 1
        }
      })

      const productHasConfiguration = {
        ProductID: this.product.ID,
        ConfigurationID: configurationID,
        Master: false,
        Template: true,
        Sorting: sorting,
        CreatedBy: userID,
        ModifiedBy: userID,
      }
      var res2 = await this.createProductHasConfiguration(productHasConfiguration, token)
      var resConfig = await this.getConfiguration(configurationID, token)

      return true
    },
    checkUsageOfConfiguration(configurationID) {
      const companyStore = useCompanyStore()
      var result = false
      companyStore.productData.companyHasProduct.forEach((p) => {
        if (p.ID !== this.activeProduct.ID && p.ConfigurationID === configurationID) result = true
      })
      return result
    },
    getNegativePrint() {
      return
      const copyConfig = JSON.parse(JSON.stringify(this.configurationModel))

      const copyConfigurationLevel_1 = JSON.parse(JSON.stringify(this.masterVorlage.configurationLevel_1))
      const copyConfigurationLevel_2 = JSON.parse(JSON.stringify(this.masterVorlage.configurationLevel_2))
      const copyConfigurationLevel_3 = JSON.parse(JSON.stringify(this.masterVorlage.configurationLevel_3))

      let level_1_IDs = []
      let level_2_IDs = []
      let level_3_IDs = []

      copyConfig.ProductsLevel_1.forEach((level_1) => {
        level_1_IDs.push(level_1.ProductLevelOneID)
        if (level_1.ProductsLevel_2.length > 0) {
          level_1.ProductsLevel_2.forEach((level_2) => {
            level_2_IDs.push(level_2.ProductLevelTwoID)
            if (level_2.ProductsLevel_3.length > 0) {
              level_2.ProductsLevel_3.forEach((level_3) => {
                level_3_IDs.push(level_3.ProductLevelThreeID)
              })
            }
          })
        }
      })

      const negativeLevel_1 = copyConfigurationLevel_1.filter((obj) => !level_1_IDs.includes(obj.ProductLevelOneID))
      const negativeLevel_2 = copyConfigurationLevel_2.filter((obj) => !level_2_IDs.includes(obj.ProductLevelTwoID))
      const negativeLevel_3 = copyConfigurationLevel_3.filter((obj) => !level_3_IDs.includes(obj.ProductLevelThreeID))

      const negativeModel = {
        level_1: negativeLevel_1,
        level_2: negativeLevel_2,
        level_3: negativeLevel_3,
      }

      return negativeModel
    },
    addCount(level, config) {
      switch (level) {
        case 1:
          config.Count >= 0 ? config.Count++ : (config.Count += 2)
          this.configurationModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.ProductLevelOneID === config.ProductLevelOneID) {
              level_1.ProductsLevel_2.forEach((prod_L2) => {
                prod_L2.Count > 0 || config.Count === 1 ? (prod_L2.Count = config.Count) : undefined
                prod_L2.ProductsLevel_3.forEach((prod_L3) => {
                  prod_L3.Count > 0 || prod_L2.Count === 1 ? (prod_L3.Count = prod_L2.Count) : undefined
                })
              })
            }
          })
          return this.configurationModel
        case 2:
          config.Count >= 0 ? config.Count++ : (config.Count += 2)
          this.configurationModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.ProductLevelOneID === config.ProductLevelOneID) {
              level_1.Count < config.Count ? (level_1.Count = config.Count) : undefined
              config.ProductsLevel_3.forEach((level_3) => {
                level_3.Count > 0 || config.Count === 1 ? (level_3.Count = config.Count) : undefined
              })
            }
          })
          return this.configurationModel
        case 3:
          config.Count >= 0 ? config.Count++ : (config.Count += 2)
          var config_L2 = this.masterVorlage.configurationLevel_2.find((c) => {
            if (c.ProductLevelTwoID === config.ProductLevelTwoID) {
              return c
            }
          })
          var config_L1 = this.masterVorlage.configurationLevel_1.find((c) => {
            if (c.ProductLevelOneID === config_L2.ProductLevelOneID) {
              return c
            }
          })
          this.configurationModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.ProductLevelOneID === config_L1.ProductLevelOneID) {
              level_1.Count < config.Count ? (level_1.Count = config.Count) : undefined
              level_1.ProductsLevel_2.forEach((level_2) => {
                if (level_2.ProductLevelTwoID === config.ProductLevelTwoID) {
                  level_2.Count < config.Count ? (level_2.Count = config.Count) : undefined
                }
              })
            }
          })
          return this.configurationModel
          break
      }
    },
    reduceCount(level, config) {
      if (config.Count === -1) return
      switch (level) {
        case 1:
          config.Count > 1 ? config.Count-- : (config.Count = 0)
          this.configurationModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.ProductLevelOneID === config.ProductLevelOneID) {
              level_1.ProductsLevel_2.forEach((prod_L2) => {
                prod_L2.Count > 0 ? (prod_L2.Count = config.Count) : undefined
                prod_L2.ProductsLevel_3.forEach((prod_L3) => {
                  prod_L3.Count > 0 ? (prod_L3.Count = prod_L2.Count) : undefined
                })
              })
              if (config.Count === 0) {
                this.configurationModel.ProductsLevel_1 = this.configurationModel.ProductsLevel_1.filter(
                  (level_1) => level_1.ProductLevelOneID !== config.ProductLevelOneID,
                )
              }
            }
          })
          return this.configurationModel
          break
        case 2:
          config.Count > 1 ? config.Count-- : (config.Count = 0)
          config.ProductsLevel_3.forEach((level_3) => {
            level_3.Count > config.Count && level_3.Count >= 0 ? (level_3.Count = config.Count) : undefined
          })
          return this.configurationModel
          break
        case 3:
          config.Count > 1 ? config.Count-- : (config.Count = 0)
          return this.configurationModel
          break
      }
    },
    setConfigurationModelCounts(value) {
      this.configurationModel.ProductsLevel_1.forEach((level_1) => {
        level_1.Count = value
        level_1.ProductsLevel_2.forEach((level_2) => {
          if (level_2.Count > 0) {
            level_2.Count = value
            level_2.ProductsLevel_3.forEach((level_3) => {
              if (level_3.Count > 0) {
                level_3.Count = value
              }
            })
          }
        })
      })
    },
    removeProdFromModel(item) {
      this.configurationModel.ProductsLevel_1.splice((el) => el.ProductLevelOneID === item.ProductLevelOneID, 1)
    },
    setConfigurationModelEnddate(value) {
      this.configurationModel.ProductsLevel_1.forEach((level_1) => {
        level_1.Limited = value
        level_1.ProductsLevel_2.forEach((level_2) => {
          level_2.Limited = value
          level_2.ProductsLevel_3.forEach((level_3) => {
            level_3.Limited = value
          })
        })
      })
    },
    addProductToConfiguration(item) {
      // create a deep copy to be sure masterModel isnt changed

      const masterCopy = JSON.parse(JSON.stringify(this.masterModel))
      var product = masterCopy.ProductsLevel_1.find((level_1) => level_1.ProductLevelOneID === item.ID)
      product.Count = item.Count
      product.Limited = item.Limited
      if (product.ProductsLevel_2.length > 0) {
        product.ProductsLevel_2.forEach((level_2) => {
          level_2.Count = level_2.Count === -1 ? level_2.Count : product.Count
          level_2.Limited = product.Limited
          if (level_2.ProductsLevel_3.length > 0) {
            level_2.ProductsLevel_3.forEach((level_3) => {
              level_3.Count = level_3.Count === -1 ? level_3.Count : product.Count
              level_3.Limited = product.Limited
            })
          }
        })
      }

      this.configurationModel.valid
        ? this.configurationModel.ProductsLevel_1.push(product)
        : (this.configurationModel.ProductsLevel_1[0] = product)

      this.configurationModel.valid = true
    },

    // got replaced by API
    async createProtocol(configurationID, token) {
      return
      const companyStore = useCompanyStore()
      await this.createMdesignConfigurationModel(configurationID, token)
      const configurationModel = JSON.parse(JSON.stringify(this.configurationModel))
      const masterModel = JSON.parse(JSON.stringify(this.masterModel))
      const versLicName = this.product.LicName

      let isSelected = []
      let isTechDep = []

      isSelected = this.getSelectedProducts(configurationModel)
      isTechDep = this.getTechnicalDependencies(masterModel, configurationModel)

      this.licSelected = isSelected
      this.licDependencies = isTechDep

      // --------------sort the technical invisible dependencys to the product level 1 by name--------------
      let indicesToRemove = []

      this.licDependencies.forEach((dependency, idx) => {
        if (dependency.Invisible) {
          var index = this.licDependencies.findIndex((obj) => dependency.ProductLevelOneID === obj.ProductLevelOneID)
          if (!this.licSelected.some((obj) => obj.Name.includes(dependency.Name))) {
            this.licDependencies.forEach((level_1) => {
              if (level_1.Name.includes(dependency.Name) && !level_1.Invisible) {
                dependency.ProductsLevel_2.forEach((dp_2) => {
                  level_1.ProductsLevel_2.push(dp_2)
                })
                if (index !== -1) indicesToRemove.push(index) // Collect index to be removed
              }
            })
          } else {
            this.licSelected.forEach((level_1) => {
              if (dependency.Name.includes(level_1.Name) && !level_1.Invisible) {
                dependency.ProductsLevel_2.forEach((dp_2) => {
                  level_1.ProductsLevel_2.push(dp_2)
                })
                if (index !== -1) indicesToRemove.push(index) // Collect index to be removed
              }
            })
          }
        }
      })

      // Remove elements in reverse order to avoid shifting issues
      indicesToRemove
        .sort((a, b) => b - a)
        .forEach((index) => {
          this.licDependencies.splice(index, 1)
        })
      //------------------------------------------------------------------------------------------------------------

      // ---------------------set the date of tech dependencies to explorers date------------------------------

      const explorer = configurationModel.ProductsLevel_1.find((obj) => obj.LicName === 'E')
      this.licDependencies.forEach((dependency) => {
        if (explorer) dependency.Limited = explorer.Limited
        if (dependency.ProductsLevel_2.length > 0) {
          dependency.ProductsLevel_2.forEach((level_2) => {
            if (explorer) level_2.Limited = explorer.Limited
          })
        }
      })
      this.licSelected.forEach((selected_1) => {
        if (selected_1.ProductsLevel_2.length > 0) {
          selected_1.ProductsLevel_2.forEach((selected_2) => {
            if (selected_2.isDependency && explorer) selected_2.Limited = explorer.Limited
          })
        }
      })
      //------------------------------------------------------------------------------------------------------------

      //----------------remove unnessesary attributes from licSelected items--------------------------------
      this.licSelected.forEach((level_1) => {
        delete level_1.Modified
        delete level_1.ModifiedBy
        delete level_1.Created
        delete level_1.CreatedBy
        if (level_1.ProductsLevel_2.length > 0) {
          level_1.ProductsLevel_2.forEach((level_2) => {
            delete level_2.Modified
            delete level_2.ModifiedBy
            delete level_2.Created
            delete level_2.CreatedBy
            if (level_2.ProductsLevel_3.length > 0) {
              level_2.ProductsLevel_3.forEach((level_3) => {
                delete level_3.Modified
                delete level_3.ModifiedBy
                delete level_3.Created
                delete level_3.CreatedBy
              })
            }
          })
        }
      })
      //------------------------------------------------------------------------------------------------

      //--------------set uncounted to explorer dependened products plus data_export products-----------
      this.licSelected.forEach((selected) => {
        if (selected.LicName === 'E') {
          selected.ProductsLevel_2.forEach((level_2) => {
            level_2.Count = level_2.Count
          })
        }
        if (selected.ProductsLevel_2.length > 0) {
          selected.ProductsLevel_2.forEach((l_2) => {
            if (l_2.LicName.includes('DATA_EXPORT')) l_2.Count = explorer.Count
          })
        }
        if (selected.LicName === 'E' || selected.LicNameParent === 'E') {
          if (selected.ProductsLevel_2.length > 0) {
            selected.ProductsLevel_2.forEach((level_2) => {
              if (level_2.LicName.includes('MODULE') || level_2.LicName.includes('LANG')) {
                level_2.Count = level_2.Count === -1 ? 0 : explorer.Count
              }
              if (level_2.ProductsLevel_3.length > 0) {
                level_2.ProductsLevel_3.forEach((level_3) => {
                  if (level_3.LicName.includes('MODULE')) {
                    level_3.Count = level_3.Count === -1 ? 0 : explorer.Count
                  }
                })
              }
            })
          }
        }

        //--------------------set explorer dependent libraries enddate to explorers----------
        if (selected.LicNameParent === 'E') {
          selected.Limited = explorer.Limited
          selected.Count = explorer.Count

          //-----------------------------------------------------------------------------------
        }
      })
      //-----------------------------------------------------------------------------------

      //----------------------create api object--------------------------------------------
      this.licApiObj = {
        version: this.product.LicName,
        companyName: companyStore.company.Name,
        customerNr: companyStore.company.CustomerNr,
        licDependencies: this.licDependencies,
        licSelected: this.licSelected,
      }

      this.licSelected.Version = this.product.Version
      this.licSelected.CompanyName = companyStore.company.Name
      this.licSelected.CustomerNr = companyStore.company.CustomerNr
    },
    getSelectedProducts(configurationModel) {
      let isSelected = []
      configurationModel.ProductsLevel_1.forEach((level_1) => {
        isSelected.push(level_1)
      })
      return isSelected
    },

    // most likely unnessesary
    getTechnicalDependencies(masterModel, configurationModel) {
      return
      let noDoubles = []
      let isTechDep = []

      this.productHasProductLevelOne.forEach((td_level_1) => {
        masterModel.ProductsLevel_1.forEach((master_1) => {
          if (master_1.ProductLevelOneID === td_level_1.ProductLevelOneID) {
            // hinzufügen generelle teschnische abhängigkeiten
            if (master_1.Invisible) {
              if (master_1.Name.includes('techDependency ')) master_1.Name = master_1.Name.split('techDependency ')[1]
              master_1.isDependency = true
              master_1.ProductsLevel_2.forEach((p2) => {
                if (p2.Name.includes('techDependency')) p2.Name = p2.Name.split('techDependency ')[1]
                p2.isDependency = true
              })
              isTechDep.push(master_1)
              noDoubles.push(master_1.ProductLevelOneID)
            } else {
              configurationModel.ProductsLevel_1.forEach((config_1) => {
                var skip = false
                if (
                  master_1.ProductLevelOneID === config_1.ProductLevelOneID ||
                  noDoubles.includes(master_1.ProductLevelOneID)
                ) {
                  skip = true
                }
                if (!skip) {
                  // isNotSelected.push(master_1)
                  if (
                    !this.configurationModel.ProductsLevel_1.some(
                      (obj) => obj.ProductLevelOneID === master_1.ProductLevelOneID,
                    )
                  ) {
                    if (master_1.ProductsLevel_2.length > 0) {
                      master_1.ProductsLevel_2.forEach((master_2) => {
                        var index = master_1.ProductsLevel_2.findIndex(
                          (obj) => obj.ProductLevelTwoID === master_2.ProductLevelTwoID,
                        )
                        if (!master_2.LicName.includes('GROUP')) {
                          master_1.ProductsLevel_2 = master_1.ProductsLevel_2.filter((el) =>
                            el.LicName.includes('GROUP'),
                          )
                        }
                      })
                    }
                    master_1.ProductsLevel_2.forEach((p) => {
                      if (p.Name.includes('techDependency ')) p.Name = p.Name.split('techDependency ')[1]
                    })
                    master_1.isDependency = true
                    isTechDep.push(master_1)
                    noDoubles.push(master_1.ProductLevelOneID)
                  }
                }
              })
            }
          }
        })
      })
      // isNotSelected.forEach((ns_level_1) => {
      //   if (ns_level_1.ProductsLevel_2.length > 0) {
      //     ns_level_1.ProductsLevel_2.forEach((leve_2) => {
      //       leve_2.ProductsLevel_3 = []
      //     })
      //   }
      // })

      // delete unnessesary attributes from objects
      // and set Count to 1
      isTechDep.forEach((level_1) => {
        level_1.Count = 1
        delete level_1.Modified
        delete level_1.ModifiedBy
        delete level_1.Created
        delete level_1.CreatedBy
        if (level_1.ProductsLevel_2.length > 0) {
          level_1.ProductsLevel_2.forEach((level_2) => {
            level_2.Count = 1
            delete level_2.Modified
            delete level_2.ModifiedBy
            delete level_2.Created
            delete level_2.CreatedBy
            if (level_2.ProductsLevel_3.length > 0) {
              level_2.ProductsLevel_3.forEach((level_3) => {
                delete level_3.Modified
                delete level_3.ModifiedBy
                delete level_3.Created
                delete level_3.CreatedBy
              })
            }
          })
        }
      })

      return isTechDep
    },
    async deleteConfigurationTemplate(template, token) {
      const productID = template.ProductID
      const configurationID = template.ID
      let databaseTemplate
      let resDelpProductHasConfiguration
      if (productID) {
        var resTemplate = await this.getProductHasConfigurationPrimary(productID, configurationID, token)
        databaseTemplate = resTemplate.data[0]
        if (databaseTemplate.Master) return { status: 401, Message: 'Cannot delete Master Confguration' }
        resDelpProductHasConfiguration = await this.deleteProductHasConfiguration(productID, configurationID, token)
        if (resDelpProductHasConfiguration.status !== 200) return resDelpProductHasConfiguration
        const resDeleteConfiguration = await this.deleteCompleteConfiguration(configurationID, token)
        if (resDeleteConfiguration.status !== 200) return resDeleteConfiguration
      }
      return { status: 200 }
    },
    async upgradeConfiguration(item, token) {
      const toUpgradeConfigurationID = item.ConfigurationID
      const oldProductID = this.upgradeData.oldProductID
      const targetProductID = this.activeProduct.ProductID
      this.resetConfigurationModel()
      this.resetConfigurationVorlage()

      var resProtocol = await this.getUpgradeConfigurationByProductAndConfigurationID(toUpgradeConfigurationID, oldProductID, targetProductID, token)
      if (resProtocol.status !== 200) return resProtocol

      const newProtocol = resProtocol.data
      newProtocol.forEach((one) => {
        one.ConfigurationHasProductLevelOne.ConfigurationID = ''
        this.configurationVorlage.configurationLevel_1.push(one.ConfigurationHasProductLevelOne)
        if (one.ListProtocolLevelTwo.length > 0) {
          one.ListProtocolLevelTwo.forEach((two) => {
            two.ConfigurationHasProductLevelTwo.ConfigurationID = ''
            this.configurationVorlage.configurationLevel_2.push(two.ConfigurationHasProductLevelTwo)
            if (two.ListProtocolLevelThree.length > 0) {
              two.ListProtocolLevelThree.forEach((three) => {
                three.ConfigurationHasProductLevelThree.ConfigurationID = ''
                this.configurationVorlage.configurationLevel_3.push(three.ConfigurationHasProductLevelThree)
              })
            }
          })
        }
      })
      const vorlageName = 'configurationVorlage'
      const modelName = 'configurationModel'
      await this.createModelWithVorlage(vorlageName, modelName)
      const infoText = `upgraded from Configuration ${item.Info} with ID ${toUpgradeConfigurationID}`
      await this.createProductConfiguration(token, infoText)
      return { status: 200 }
    },
    async updateConfigurationsLicence(item, token) {
      const resConfigurationLicence = await this.getConfigurationLicenceCompanyHasProductID(item.ID, token)
      const companyStore = useCompanyStore()
      const userStore = useUserStore()
      if (resConfigurationLicence.status !== 200) return resConfigurationLicense
      let configurationsLicence = resConfigurationLicence.data.map((lic) => ({ ...lic }))
      if (configurationsLicence.length <= 0) return { status: 200 }

      const now = companyStore.getNow()

      configurationsLicence.forEach((obj) => {
        obj.ContactHasProductID = 'NULL'
        obj.Version = this.product.Version
        obj.CompanyHasProductID = this.activeProduct.ID
        obj.Created = now
        obj.Modified = now
        obj.CreatedBy = userStore.userData.ID
        obj.ModifiedBy = userStore.userData.ID
        obj.ID = ''
      })

      var results = []
      for (const lic of configurationsLicence) {
        try {
          const resLic = await this.createConfigurationLicence(lic, token);
          if (resLic.status !== 200) {
            results.push(resLic); // Add error result
          } else {
            results.push(resLic); // Add success result
          }
        } catch (e) {
          results.push({ status: 400, message: e.message });
        }
      }

      var res = null
      results.forEach((promise) => {
        if (promise.status !== 200)
          res = promise
      })

      if (res)
        return res

      return { status: 200 }
    },
    async getTemplatesByVersion(token, productsByVersion) {
      if (productsByVersion.length > 0) {
        const promises = productsByVersion.map(async (p) => {
          try {
            var respProdHasConfig = await this.getProductHasConfiguration(p.ID, token)
            if (respProdHasConfig.status !== 200) return

            const configurations = respProdHasConfig.data

            const configPromises = configurations.map(async (config) => {
              if (config.Template) {
                const res = await this.getConfiguration(config.ConfigurationID, token);
                const obj = res.data[0];
                obj.ConfigurationID = config.ConfigurationID
                obj.Sorting = config.Sorting
                obj.ProductID = config.ProductID;
                this.configurationTemplates.push(obj);
              }
            });

            // Wait for all configuration promises to resolve
            await Promise.all(configPromises);
          } catch (error) {
            // console.log('error Product ', p.ID, error)
            return false
          }
        })
        await Promise.all(promises)
        this.configurationTemplates.sort((a, b) => a.Sorting - b.Sorting)
      }
    },

    async getTemplatesByProdHasConfig(token) {
      this.configurationTemplates = []
      var respProdHasConfig = await this.getProductHasConfiguration(this.product.ID, token)
      if (respProdHasConfig.status !== 200) {
        if (respProdHasConfig.status === 404) return respProdHasConfig
      }
      this.productHasConfiguration = respProdHasConfig.data
      this.productHasConfiguration.forEach((config) => {
        if (config.Master === true) this.masterModel.ID = config.ConfigurationID
      })
      if (this.configurationTemplates.length === 0) {
        this.productHasConfiguration.forEach(async (config) => {
          if (config.Template === true) {
            var res = await this.getConfiguration(config.ConfigurationID, token)
            this.configurationTemplates.push(res.data[0])
          }
        })
      }
      return { status: 200 }
    },
    async getAllConfigurations(token) {
      var response = await httpGetAuth(ApiNames.configurations, {}, {}, token)

      return response
    },
    async copyCompleteConfiguration(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPostAuth(ApiNames.copycompleteconfiguration, obj, {}, token)

      return response
    },
    async getImages() {
      const userStore = useUserStore()
      const companyStore = useCompanyStore()
      this.masterModel.ProductsLevel_1.forEach(async (level_1) => {
        if (level_1.Image === 0) return
        var idFromTable = level_1.ProductLevelOneID
        var tableName = 'product_level_one'
        var resDataFile = await companyStore.getFileReferenceByTable(idFromTable, tableName, userStore.token)
        if (resDataFile.status !== 200) {
          Swal.fire({
            icon: 'error',
            title: this.$t('errorDownloadImage'),
          }).then((result) => {
            return result
          })
          return
        }
        var dataFileID = resDataFile.data[0].DataFileID
        var resFile = await companyStore.getDownloadFile(dataFileID, false, userStore.token)
        if (resFile.status !== 200) {
          Swal.fire({
            icon: 'error',
            title: this.$t('errorDownloadImage'),
          }).then((result) => {
            return result
          })
          return
        }
        const reader = new FileReader()
        reader.readAsDataURL(resFile.data)
        reader.onload = () => {
          this.imageData[level_1.ProductLevelOneID] = reader.result
          // var obj = this.configurationModel.ProductsLevel_1.find((el) => el.ProductLevelOneID===level_1.ProductLevelOneID)
          // obj.imageRegular = reader.result
        }
      })
    },
    async getProductImage(token) {
      const companyStore = useCompanyStore()
      // const token = this.userStore.token
      // var idFromTable = this.companyStore.productData.editProduct.ID
      let imageData = ''
      var idFromTable = this.product.ID
      var tableName = 'product'
      var resDataFile = await companyStore.getFileReferenceByTable(idFromTable, tableName, token)
      if (resDataFile.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorDownloadImage'),
        }).then((result) => {
          return result
        })
        return
      }
      var dataFileID = resDataFile.data[0].DataFileID
      var resFile = await companyStore.getDownloadFile(dataFileID, false, token)
      if (resFile.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorDownloadImage'),
        }).then((result) => {
          return result
        })
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(resFile.data)
      reader.onload = () => {
        this.product.ImageData = reader.result
      }
    },
    async getLicence(ConfigurationID, token) {
      const params = {
        id: ConfigurationID,
      }
      // var response = await httpGetAuth(ApiNames.licence, params, {}, token)
      var response = await httpGetLicenceFile(ApiNames.licence, params, {}, token)

      return response
    },

    async getLicenceByCombi(hostFileID, configID, productID, token) {
      const params = {
        id: configID,
        configurationLicenceId: hostFileID,
        productID: productID
      }
      // var response = await httpGetAuth(ApiNames.licence, params, {}, token)
      var response = await httpGetLicenceFile(
        ApiNames.licence_with_configurationlicenceid,
        params,
        { responseType: 'blob' },
        token,
      )

      return response
    },

    async getProductLevels_1(token) {
      var response = await httpGetAuth(ApiNames.productlevels_1, {}, {}, token)
      this.productsLevel_1 = response.data
      return response
    },

    async getProductLevels_2(token) {
      var response = await httpGetAuth(ApiNames.productlevels_2, {}, {}, token)
      this.productsLevel_2 = response.data
      return response
    },

    async getProductLevels_3(token) {
      var response = await httpGetAuth(ApiNames.productlevels_3, {}, {}, token)
      this.productsLevel_3 = response.data
      return response
    },

    async getConfigurationLevel_1(configID, token) {
      const params = {
        id: configID,
      }
      var response = await httpGetAuth(ApiNames.configurationhasproductlevel_1_configurationid, params, {}, token)

      return response
    },

    async getConfigurationLevel_2(configID, token) {
      const params = {
        id: configID,
      }
      var response = await httpGetAuth(ApiNames.configurationhasproductlevel_2_configurationid, params, {}, token)

      return response
    },

    async getProductHasConfigurationPrimary(productID, configID, token) {
      const params = {
        productId: productID,
        configurationId: configID,
      }
      var response = await httpGetAuth(ApiNames.producthasconfiguration_primary, params, {}, token)

      return response
    },

    async getProductHasProductLevelOne(productID, token) {
      const params = {
        id: productID,
      }
      var response = await httpGetAuth(ApiNames.producthasproductlevelone, params, {}, token)

      return response
    },

    async getConfigurationLevel_3(configID, token) {
      const params = {
        id: configID,
      }
      var response = await httpGetAuth(ApiNames.configurationhasproductlevel_3_configurationid, params, {}, token)

      return response
    },
    async getConfigurationLicence(id, token) {
      const params = {
        id: id,
      }
      var response = await httpGetAuth(ApiNames.configuration_licence, params, {}, token)

      return response
    },
    async getProductHasConfiguration(productID, token) {
      const params = {
        id: productID,
      }
      var response = await httpGetAuth(ApiNames.producthasconfiguration, params, {}, token)

      return response
    },

    async getConfigurationHasProductLevel_1_Combi(productLevelOneId, configurationId, token) {
      const params = {
        productLevelOneId: productLevelOneId,
        configurationId: configurationId,
      }
      var response = await httpGetAuth(ApiNames.configurationhasproductlevel_1_combi, params, {}, token)

      return response
    },
    async getConfigurationHasProductLevel_2_Combi(productLevelOneId, productLevelTwoId, configurationId, token) {
      const params = {
        productLevelOneId: productLevelOneId,
        productLevelTwoId: productLevelTwoId,
        configurationId: configurationId,
      }
      var response = await httpGetAuth(ApiNames.configurationhasproductlevel_2_combi, params, {}, token)

      return response
    },
    async getConfigurationHasProductLevel_3_Combi(productLevelTwoId, productLevelThreeId, configurationId, token) {
      const params = {
        productLevelTwoId: productLevelTwoId,
        productLevelThreeId: productLevelThreeId,
        configurationId: configurationId,
      }
      var response = await httpGetAuth(ApiNames.configurationhasproductlevel_3_combi, params, {}, token)

      return response
    },
    async getConfigurationLicenceConfigurationId(configID, token) {
      const params = {
        id: configID,
      }
      var response = await httpGetAuth(ApiNames.configuration_licenceconfigurationid, params, {}, token)

      return response
    },

    async getConfigurationsByCompanyID(companyID, token) {
      const params = {
        id: companyID,
      }
      var response = await httpGetAuth(ApiNames.configurationbycompanyid, params, {}, token)

      return response
    },

    async getConfigurationLicenceCompanyHasProductID(compHasProdID, token) {
      this.hostIDFiles = []
      const params = {
        id: compHasProdID,
      }
      var response = await httpGetAuth(ApiNames.configuration_licencecompanyhasproductid, params, {}, token)
      if (response.status === 200) this.hostIDFiles = response.data
      return response
    },

    async getUpgradeConfigurationByProductAndConfigurationID(toUpgradeConfigurationID, oldProductID, targetProductID, token) {

      const params = {
        toUpgradeConfigurationID: toUpgradeConfigurationID,
        oldProductID: oldProductID,
        targetProductID: targetProductID
      }
      var response = await httpGetAuth(ApiNames.upgradeconfigurationbyproductandconfigurationid, params, {}, token)

      return response
    },

    async createConfiguration(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfiguration, obj, headerConfig)

      return response
    },
    async createConfigurationLicence(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfiguration_licence, obj, headerConfig)

      return response
    },

    async createConfigurationHistory(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfigurationhistory, obj, headerConfig)

      return response
    },

    async createCompanyHasProduct(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createcompanyhasproduct, obj, headerConfig)

      return response
    },
    async createProductHasConfiguration(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createproducthasconfiguration, obj, headerConfig)

      return response
    },
    async CreateConfigurationHasProductLevel_1(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfigurationhasproductlevel_1, obj, headerConfig)

      return response
    },
    async CreateConfigurationHasProductLevel_2(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfigurationhasproductlevel_2, obj, headerConfig)

      return response
    },
    async CreateConfigurationHasProductLevel_3(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfigurationhasproductlevel_3, obj, headerConfig)

      return response
    },
    async CreateConfigurationHasProductLevel_1_many(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfigurationhasproductlevel_1_many, obj, headerConfig)

      return response
    },
    async CreateConfigurationHasProductLevel_2_many(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfigurationhasproductlevel_2_many, obj, headerConfig)

      return response
    },
    async CreateConfigurationHasProductLevel_3_many(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createconfigurationhasproductlevel_3_many, obj, headerConfig)

      return response
    },
    async createProductLevelOne(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createproductlevel_1, obj, headerConfig)

      return response
    },

    async uploadLicenceFilesForConfiguration(contactID, companyID, companyHasProductID, file, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }

      var formData = new FormData()
      formData.append(file.name, file)

      var response = await httpPostUploadLicense(
        ApiNames.uploadlicencefilesforconfiguration,
        contactID,
        companyID,
        companyHasProductID,
        formData,
        headerConfig)

      return response
    },

    async getProductLevelOne(productLevelOneID, token) {
      const params = {
        id: productLevelOneID,
      }
      var response = await httpGetAuth(ApiNames.productlevel_1, params, {}, token)

      return response
    },
    async getProductLevelTwo(productLevelTwoID, token) {
      const params = {
        id: productLevelTwoID,
      }
      var response = await httpGetAuth(ApiNames.productlevel_2, params, {}, token)

      return response
    },
    async getProductLevelThree(productLevelThreeID, token) {
      const params = {
        id: productLevelThreeID,
      }
      var response = await httpGetAuth(ApiNames.productlevel_3, params, {}, token)

      return response
    },
    async getProductHasProductLevelOnePrimary(productId, productLevelOneId, token) {
      const params = {
        productId: productId,
        productLevelOneId: productLevelOneId,
      }
      var response = await httpGetAuth(ApiNames.producthasproductlevelone_primary, params, {}, token)

      return response
    },
    async getProtocolComplete(configurationId, productID, token) {
      const params = {
        configurationId: configurationId,
        productID: productID,
      }
      var response = await httpGetAuth(ApiNames.protocolcomplete, params, {}, token)

      return response
    },
    async getProtocolByConfiguration(configurationId, token) {
      const params = {
        configurationId: configurationId,
      }
      var response = await httpGetAuth(ApiNames.protocolbyconfiguration, params, {}, token)

      return response
    },
    async getProtocolTechDependencys(version, versiontype, token) {
      const params = {
        version: version,
        versiontype: versiontype
      }
      var response = await httpGetAuth(ApiNames.protocoltechdependencys, params, {}, token)

      return response
    },

    async getProtocolProductLevelOneForProduct(version, versiontype, token) {
      const params = {
        version: version,
        versiontype: versiontype
      }
      var response = await httpGetAuth(ApiNames.protocolproductleveloneforproduct, params, {}, token)

      return response
    },

    async createProductLevelTwo(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createproductlevel_2, obj, headerConfig)

      return response
    },
    async createProductLevelThree(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createproductlevel_3, obj, headerConfig)

      return response
    },

    async createProductHasProductLevelOne(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createproducthasproductlevelone, obj, headerConfig)

      return response
    },

    async updateProductLevelOne(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateproductlevel_1, obj, headerConfig)

      return response
    },
    async updateProductLevelTwo(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateproductlevel_2, obj, headerConfig)

      return response
    },
    async updateProductLevelThree(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateproductlevel_3, obj, headerConfig)

      return response
    },
    async updateCompanyHasProduct(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updatecompanyhasproduct, obj, headerConfig)

      return response
    },

    async updateConfigurationLicence(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateconfiguration_licence, obj, headerConfig)

      return response
    },

    async updateConfigurationHasProductLevelOne(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateconfigurationhasproductlevel_1, obj, headerConfig)

      return response
    },

    async updateConfigurationHasProductLevelTwo(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateconfigurationhasproductlevel_2, obj, headerConfig)

      return response
    },

    async updateProductHasProductLevelOne(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateproducthasproductlevelone, obj, headerConfig)

      return response
    },

    async updateConfigurationHasProductLevelThree(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateconfigurationhasproductlevel_3, obj, headerConfig)

      return response
    },

    async updateConfigurationLicenceForeignkey(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateconfiguration_licence_foreignkey, obj, headerConfig)

      return response
    },

    async uploadProductLevelOneImage(file, token, productLevelOneID) {
      const headerConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      var formData = new FormData()

      formData.append('image', file)

      var response = await httpPostProductLevelOneImage(
        ApiNames.uploadproductleveloneimage,
        productLevelOneID,
        formData,
        headerConfig,
      )

      return response
    },

    async deleteProductHasConfiguration(productID, configID, token) {
      const params = {
        productID: productID,
        configurationID: configID,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteproducthasconfiguration, params, header)

      return response
    },

    async deleteCompleteConfiguration(configID, token) {
      const params = {
        configurationId: configID,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deletecompleteconfiguration, params, header)

      return response
    },
    async deleteConfigurationHasProductLevelOneCombi(productLevelOneId, configurationId, token) {
      const params = {
        productLevelOneId: productLevelOneId,
        configurationId: configurationId,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteconfigurationhasproductlevel_1_combi, params, header)

      return response
    },
    async deleteConfigurationHasProductLevelTwoCombi(productLevelOneId, productLevelTwoId, configurationId, token) {
      const params = {
        productLevelOneId: productLevelOneId,
        productLevelTwoId: productLevelTwoId,
        configurationId: configurationId,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteconfigurationhasproductlevel_2_combi, params, header)

      return response
    },
    async deleteProductLevel(propductLvlId, level, token) {
      const params = {
        id: propductLvlId,
      }
      const header = { Authorization: `Bearer ${token}` }
      var apiUrl = ''
      switch (level) {
        case 1:
          apiUrl = ApiNames.deleteproductlevel_1
          break
        case 2:
          apiUrl = ApiNames.deleteproductlevel_2
          break
        case 3:
          apiUrl = ApiNames.deleteproductlevel_3
          break
      }
      var response = await httpDelete(apiUrl, params, header)

      return response
    },
    async deleteConfigurationHasProductLevelThreeCombi(productLevelTwoId, productLevelThreeId, configurationId, token) {
      const params = {
        productLevelTwoId: productLevelTwoId,
        productLevelThreeId: productLevelThreeId,
        configurationId: configurationId,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteconfigurationhasproductlevel_3_combi, params, header)

      return response
    },
    fireError(text) {
      Swal.fire({
        title: i18n.t('errorLoadConfiguration'),
        text: text,
        type: 'warning',
        confirmButtonClass: 'md-button md-success btn-fill',
        buttonsStyling: false,
      }).then(() => {
        // router push
      })
    },
    async fireAnyError(title, text) {
      await Swal.fire({
        title: title,
        text: text,
        type: 'warning',
        confirmButtonClass: 'md-button md-success btn-fill',
        buttonsStyling: false,
      }).then((result) => {
        return result
      })
    },
  },
})
